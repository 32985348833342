import React, { useState } from 'react';
import FormEntry from './FormEntry';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Avatar from '@material-ui/core/Avatar';
import { FormControlLabel } from '@material-ui/core';
import SmartFormEntry from "./SmartFormEntry";
import StyledAccordion from "../../../common/StyledAccordion";

const FormEntryList = (props) => {
    let countEntries = props.entries.length + props.smartFormEntries.length;
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    const populateEntries = (entries) => {

        try {
            if (Array.isArray(entries) && entries.length > 0) {
                return entries.map((entry, index) => (
                    <FormEntry key={index} entry={entry} />
                ))
            }
        } catch (e) {
            console.log(e);
        }

    }

    const populateSmartEntries = (entries) => {

        try {
            if (Array.isArray(entries) && entries.length > 0) {
                return entries.map((entry, index) => (
                    <SmartFormEntry key={index} entry={entry} />
                ))
            }
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <React.Fragment>
            {
                (props.entries || props.smartFormEntries) && (
                    <StyledAccordion>
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                // onClick={(event) => event.stopPropagation()}
                                // onFocus={(event) => event.stopPropagation()}
                                control={
                                    <React.Fragment>
                                        <div className="collaborator__list__parant">
                                            <div className="collaborator__list__left"> <span className="collaborator__left__icon"><svg width="23" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 4.86748V19.2829C16 20.3685 15.1046 21.2486 14 21.2486H2C0.895417 21.2486 0 20.3685 0 19.2829V4.86748C0 3.78182 0.895417 2.90174 2 2.90174H5.33333C5.33333 1.45652 6.52958 0.280762 8 0.280762C9.47042 0.280762 10.6667 1.45652 10.6667 2.90174H14C15.1046 2.90174 16 3.78182 16 4.86748ZM8 1.91887C7.44771 1.91887 7 2.35891 7 2.90174C7 3.44457 7.44771 3.88461 8 3.88461C8.55229 3.88461 9 3.44457 9 2.90174C9 2.35891 8.55229 1.91887 8 1.91887ZM12 6.58749V5.76844C12 5.70327 11.9737 5.64077 11.9268 5.59469C11.8799 5.54861 11.8163 5.52272 11.75 5.52272H4.25C4.1837 5.52272 4.12011 5.54861 4.07322 5.59469C4.02634 5.64077 4 5.70327 4 5.76844V6.58749C4 6.65266 4.02634 6.71516 4.07322 6.76124C4.12011 6.80732 4.1837 6.83321 4.25 6.83321H11.75C11.8163 6.83321 11.8799 6.80732 11.9268 6.76124C11.9737 6.71516 12 6.65266 12 6.58749Z" fill="white" />
                                            </svg>
                                            </span> <span className="collaborator__list--title text-dark-blue">Form Entry</span>   <Avatar>{countEntries}</Avatar> </div>

                                        </div>

                                    </React.Fragment>
                                }
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <>
                                {
                                    props.entries.length == 0 && props.smartFormEntries.length == 0 ? (
                                        <div className="m-widget2__item complete__form">
                                            {/* <div className="m-widget2__desc">
                                                <span style={{display:"flex",justifyContent:"center"}}>No data available</span>
                                            </div> */}
                                        </div>
                                    ) : (
                                        <>
                                            {populateSmartEntries(props.smartFormEntries)}
                                            {populateEntries(props.entries)}
                                        </>
                                    )
                                }
                            </>
                        </AccordionDetails>
                    </StyledAccordion>
                )
            }
        </React.Fragment>
    )
}

export default FormEntryList;
